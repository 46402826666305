// export const API_BASE = 'https://kapi.kubyx.cz'
export const API_BASE = 'https://devapi.kubyx.cz'
// export const API_BASE = 'http://localhost:3007'
export const JSON_HEADER = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
}
export const HTML_HEADER = {
    'Content-Type': 'text/html',
    'Accept': 'text/html'
}